import React, {ReactElement} from 'react';
import ReactHTMLParser from 'react-html-parser';

// styles
import './style.scss';

// images
import Image1 from '../../../assets/images/sooyoung.png';
import Image2 from '../../../assets/images/slava.png';
import Image3 from '../../../assets/images/laptop.png';
import Image4 from '../../../assets/images/brett.png';
import Image5 from '../../../assets/images/board.png';
import Image6 from '../../../assets/images/stephan.png';
import Image7 from '../../../assets/images/simon.png';

// interface
interface IProps {
  data_title: any;
  about_us_image_header_text_title: any;
  about_us_image_header_description: any;
  about_us_text_block_1_title: any;
  about_us_text_block_1_description: any;
  about_us_text_block_2_title: any;
  about_us_text_block_2_description: any;
  about_us_text_block_3_title: any;
  about_us_text_block_3_description: any;
}

export default function AboutUs(props: IProps): ReactElement {
  // variables
  const {
    data_title,
    about_us_image_header_text_title,
    about_us_image_header_description,
    about_us_text_block_1_title,
    about_us_text_block_1_description,
    about_us_text_block_2_title,
    about_us_text_block_2_description,
    about_us_text_block_3_title,
    about_us_text_block_3_description,
  } = props;

  return (
    <div id="about-us">
      <div className="container">
        <div className="section-header-text">
          <h1>{data_title}</h1>
        </div>
        <div className="image-layer">
          <div className="one half-image-layer">
            <div className="one">
              <img src={Image1} alt="about-1" />
            </div>
            <div className="one column-image-layer">
              <div className="one">
                <img src={Image2} alt="about-2" />
              </div>
              <div className="one">
                <img src={Image3} alt="about-3" />
              </div>
            </div>
          </div>
          <div className="two text-with-image-layer">
            <div className="text-layer">
              <div>
                <h1>{about_us_image_header_text_title}</h1>
                <p>{ReactHTMLParser(about_us_image_header_description)}</p>
              </div>
            </div>
            <div className="text-image-layer">
              <div>
                <img src={Image4} alt="about-4" />
              </div>
              <div>
                <img src={Image5} alt="about-5" />
              </div>
              <div>
                <img src={Image6} alt="about-6" />
              </div>
              <div>
                <img src={Image7} alt="about-7" />
              </div>
            </div>
          </div>
          <div className="tablet-image-with-text">
            <div>
              <img src={Image4} alt="about-4" />
            </div>
            <div>
              <img src={Image5} alt="about-5" />
            </div>
            <div>
              <img src={Image6} alt="about-6" />
            </div>
            <div>
              <img src={Image7} alt="about-7" />
            </div>
          </div>
        </div>
        <div className="about-us-text-blocks">
          <div>
            <h1>{ReactHTMLParser(about_us_text_block_1_title)}</h1>
            <p>{about_us_text_block_1_description}</p>
          </div>
          <div>
            <h1>{ReactHTMLParser(about_us_text_block_2_title)}</h1>
            <p>{about_us_text_block_2_description}</p>
          </div>
          <div>
            <h1>{ReactHTMLParser(about_us_text_block_3_title)}</h1>
            <p>{about_us_text_block_3_description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
