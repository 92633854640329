import React, {ReactElement} from 'react';
import ReactHTMLParser from 'react-html-parser';

// styles
import './style.scss';

// images
import ArrowLeft from '../../../assets/images/graph-arrow-left.png';
import ArrowRight from '../../../assets/images/graph-arrow-right.png';
import PenIcon from '../../../assets/images/pen-icon.svg';
import CodeIcon from '../../../assets/images/code-icon.svg';
import GraphIcon from '../../../assets/images/graph-icon.svg';
import SettingsIcon from '../../../assets/images/settings-icon.svg';
import LightBulb from '../../../assets/images/light-bulb.png';
import SemiLightBulb from '../../../assets/images/semi-light-bulb.png';
import FullLightBulb from '../../../assets/images/full-light-bulb.png';
import LightArrow from '../../../assets/images/light-arrow.png';
import SemiLightArrow from '../../../assets/images/semi-light-arrow.png';
import FullLightArrow from '../../../assets/images/full-light-arrow.png';
import LightRocket from '../../../assets/images/light-rocket.png';
import SemiLightRocket from '../../../assets/images/semi-light-rocket.png';
import FullLightRocket from '../../../assets/images/full-light-rocket.png';
import LightRocketArrow from '../../../assets/images/light-rocket-arrow.png';
import SemiLightRocketArrow from '../../../assets/images/semi-light-rocket-arrow.png';
import FullLightRocketArrow from '../../../assets/images/full-light-rocket-arrow.png';
import TabletMobileLightBulb from '../../../assets/images/tablet-mobile-light-bulb.svg';
import TabletMobileSemiLightBulb from '../../../assets/images/tablet-mobile-semi-light-bulb.svg';
import TabletMobileFullLightBulb from '../../../assets/images/tablet-mobile-full-light-bulb.svg';
import FlowArrow from '../../../assets/images/graph-arrow.svg';
import TabletMobileLightRocket from '../../../assets/images/tablet-mobile-light-rocket.svg';
import TabletMobileSemiLightRocket from '../../../assets/images/tablet-mobile-semi-light-rocket.svg';
import TabletMobileFullLightRocket from '../../../assets/images/tablet-mobile-full-light-rocket.svg';

// interface
interface IProps {
  data_graph_title_first: any;
  data_graph_title_second: any;
  data_graph_title_third: any;
  data_graph_box_title_first: any;
  data_graph_box_description_first: any;
  data_graph_box_title_second: any;
  data_graph_box_description_second: any;
  data_graph_box_title_third: any;
  data_graph_box_description_third: any;
  data_graph_box_title_fourth: any;
  data_graph_box_description_fourth: any;
}

export default function VentureGraphBlock(props: IProps): ReactElement {
  // variables
  const {
    data_graph_title_first,
    data_graph_title_second,
    data_graph_title_third,
    data_graph_box_title_first,
    data_graph_box_description_first,
    data_graph_box_title_second,
    data_graph_box_description_second,
    data_graph_box_title_third,
    data_graph_box_description_third,
    data_graph_box_title_fourth,
    data_graph_box_description_fourth,
  } = props;

  return (
    <div id="venture-graph-block">
      <div className="container">
        <div className="venture-graph-block-header">
          <div>{data_graph_title_first}</div>
          <img src={ArrowLeft} className="arrow-left" alt="arrow-left" />
          <div className="bigger-text">{data_graph_title_second}</div>
          <img src={ArrowRight} className="arrow-right" alt="arrow-right" />
          <div>{data_graph_title_third}</div>
        </div>
        <div className="venture-graph-block-content">
          <div className="tablet-flows">
            <div className="bulb-rocker-con">
              <div className="light">
                <img src={TabletMobileLightBulb} alt="bulb-icon" />
              </div>
              <div className="semi-light">
                <img src={TabletMobileSemiLightBulb} alt="bulb-icon" />
              </div>
              <div>
                <img src={TabletMobileFullLightBulb} alt="bulb-icon" />
              </div>
              <div className="semi-light">
                <img src={TabletMobileSemiLightBulb} alt="bulb-icon" />
              </div>
              <div className="light">
                <img src={TabletMobileLightBulb} alt="bulb-icon" />
              </div>
            </div>
            <div className="flow-text">{data_graph_title_first}</div>
            <div className="flow-arrow">
              <img src={FlowArrow} alt="flow-arrow" />
            </div>
          </div>
          <div className="flows">
            <div className="bulbs">
              <div className="bulb-con">
                <img src={LightBulb} alt="light-bulb" />
              </div>
              <img src={LightArrow} alt="bulb-arrow" />
            </div>
            <div className="bulbs">
              <div className="bulb-con">
                <img src={SemiLightBulb} alt="light-bulb" />
              </div>
              <img src={SemiLightArrow} alt="bulb-arrow" />
            </div>
            <div className="bulbs">
              <div className="bulb-con">
                <img src={FullLightBulb} alt="light-bulb" />
              </div>
              <img src={FullLightArrow} alt="bulb-arrow" />
            </div>
            <div className="bulbs">
              <div className="bulb-con">
                <img src={SemiLightBulb} alt="light-bulb" />
              </div>
              <img src={SemiLightArrow} alt="bulb-arrow" />
            </div>
            <div className="bulbs">
              <div className="bulb-con">
                <img src={LightBulb} alt="light-bulb" />
              </div>
              <img src={LightArrow} alt="bulb-arrow" />
            </div>
          </div>
          <div>
            <div className="venture-graph-box">
              <div className="line-straight"></div>
              <div className="line-horizontal"></div>
              <div className="box first-box">
                <div>
                  <div className="icon-con">
                    <img src={PenIcon} alt="pen-icon" />
                  </div>
                  <div className="icon-text-con">
                    <h3>{data_graph_box_title_first}</h3>
                    <p>{ReactHTMLParser(data_graph_box_description_first)}</p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div>
                  <div className="icon-con">
                    <img src={CodeIcon} alt="code-icon" />
                  </div>
                  <div className="icon-text-con">
                    <h3>{data_graph_box_title_second}</h3>
                    <p>{ReactHTMLParser(data_graph_box_description_second)}</p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="small">
                  <div className="icon-con">
                    <img src={GraphIcon} alt="graph-icon" />
                  </div>
                  <div className="icon-text-con">
                    <h3>{data_graph_box_title_third}</h3>
                    <p>{ReactHTMLParser(data_graph_box_description_third)}</p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div>
                  <div className="icon-con">
                    <img src={SettingsIcon} alt="settings-icon" />
                  </div>
                  <div className="icon-text-con">
                    <h3>{data_graph_box_title_fourth}</h3>
                    <p>{ReactHTMLParser(data_graph_box_description_fourth)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flows flows-rocket">
            <div className="rockets">
              <img src={LightRocketArrow} alt="rocket-arrow" />
              <div className="rocket-con">
                <img src={LightRocket} alt="light-rocket" />
              </div>
            </div>
            <div className="rockets">
              <img src={SemiLightRocketArrow} alt="rocket-arrow" />
              <div className="rocket-con">
                <img src={SemiLightRocket} alt="light-rocket" />
              </div>
            </div>
            <div className="rockets">
              <img src={FullLightRocketArrow} alt="rocket-arrow" />
              <div className="rocket-con">
                <img src={FullLightRocket} alt="light-rocket" />
              </div>
            </div>
            <div className="rockets">
              <img src={SemiLightRocketArrow} alt="rocket-arrow" />
              <div className="rocket-con">
                <img src={SemiLightRocket} alt="light-rocket" />
              </div>
            </div>
            <div className="rockets">
              <img src={LightRocketArrow} alt="rocket-arrow" />
              <div className="rocket-con">
                <img src={LightRocket} alt="light-rocket" />
              </div>
            </div>
          </div>
          <div className="tablet-graph-text">{data_graph_title_second}</div>
          <div className="tablet-flows rocket-tablet-flows">
            <div className="flow-arrow">
              <img src={FlowArrow} alt="flow-arrow" />
            </div>
            <div className="bulb-rocker-con">
              <div className="light">
                <img src={TabletMobileLightRocket} alt="bulb-icon" />
              </div>
              <div className="semi-light">
                <img src={TabletMobileSemiLightRocket} alt="bulb-icon" />
              </div>
              <div>
                <img src={TabletMobileFullLightRocket} alt="bulb-icon" />
              </div>
              <div className="semi-light">
                <img src={TabletMobileSemiLightRocket} alt="bulb-icon" />
              </div>
              <div className="light">
                <img src={TabletMobileLightRocket} alt="bulb-icon" />
              </div>
            </div>
            <div className="flow-text">{data_graph_title_third}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
