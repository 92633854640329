import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';

// style
import './style.scss';

export default function NoMatch(props: any): ReactElement {
  // use effects
  React.useEffect(() => {});

  return (
    <div id="nomatch">
      <div>
        <h1>Page not Found!</h1>
        <h4>
          Back to <Link to="/">Home</Link>
        </h4>
      </div>
    </div>
  );
}
