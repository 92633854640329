import React, {ReactElement} from 'react';
import ReactHTMLParser from 'react-html-parser';

// styles
import './style.scss';

// interface
interface IProps {
  data_title: any;
  data_list: any;
}

export default function Technologies(props: IProps): ReactElement {
  // variables
  const {data_title, data_list} = props;

  // custom functions
  const handleListDisplay = (text: string) => {
    let str = text;
    if (str.indexOf('<br />\r\n')) {
      str = str.replace(/<[^>]+>/g, ' <span>/</span> ');
    }
    return ReactHTMLParser(str);
  };

  return (
    <div id="technologies">
      <div className="container">
        <h3>{data_title}</h3>
        <div className="technologies-list">{handleListDisplay(data_list)}</div>
      </div>
    </div>
  );
}
