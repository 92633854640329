import {combineReducers} from 'redux';

// reducers
import language from './language';
import homepage from './homepage';
import navigation from './navigation';

const rootReducer = combineReducers({homepage, language, navigation});

export default rootReducer;
