import axios from 'axios';
import {API, HOMEPAGE_URL, ACCESS_TOKEN} from '../constant/api';

export const GET_HOMEPAGE_DATA = () => (dispatch: any) => {
  axios({
    method: 'get',
    url: HOMEPAGE_URL,
    baseURL: API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + ACCESS_TOKEN,
    },
  })
    .then(response => {
      dispatch({
        type: GET_HOMEPAGE_DATA,
        data: response.data.fields,
      });
    })
    .catch(error => {
      console.log(error.response);
    });
};
