import React, {ReactElement} from 'react';
import ReactHTMLParser from 'react-html-parser';

// styles
import './style.scss';

// images
import DarkLogo from '../../../assets/images/dark-logo.svg';
import DarkMobileLogo from '../../../assets/images/footer-logo.svg';

// interface
interface IProps {
  data_address_1_country: any;
  data_address_1_ca: any;
  data_address_2_country: any;
  data_address_2_ca: any;
}

export default function Footer(props: IProps): ReactElement {
  // variables
  const date = new Date();
  const year = date.getFullYear();
  const {
    data_address_1_country,
    data_address_1_ca,
    data_address_2_country,
    data_address_2_ca,
  } = props;

  return (
    <div id="footer">
      <div className="container">
        <div className="two footer-logo">
          <div className="desktop">
            <img src={DarkLogo} alt="dark-fastvb-logo" />
            <p>&copy; {year} Fast Venture Builder. All Rights Reserved</p>
          </div>
          <div className="mobile">
            <img src={DarkMobileLogo} alt="mobile-logo" />
          </div>
        </div>
        <div className="one footer-locations">
          <div className="footer-locations-content">
            <div>
              <h4>{data_address_1_country}</h4>
              <p>{ReactHTMLParser(data_address_1_ca)}</p>
            </div>
            <div>
              <h4>{data_address_2_country}</h4>
              <p>{ReactHTMLParser(data_address_2_ca)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile mobile">
        <img src={DarkLogo} alt="dark-logo" />
        <p>&copy; {year} Fast Venture Builder. All Rights Reserved</p>
      </div>
    </div>
  );
}
