import {GET_HOMEPAGE_DATA} from '../actions/homepage';

// variables
const initialState = {
  data: Object || {},
};

export default function(state: object = initialState, action: any = null) {
  switch (action.type) {
    case GET_HOMEPAGE_DATA:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}
