import * as React from 'react';
import {Route, Switch} from 'react-router-dom';

// pages
import Homepage from '../../pages/homepage';
import NoMatch from '../../pages/no-match';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route component={NoMatch} />
    </Switch>
  );
}
