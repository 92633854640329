import React, {ReactElement} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';

// actions
import {SET_LANGUAGE} from '../../actions/langugage';

// styles
import './style.scss';

// images
import MobileLogo from '../../../assets/images/mobile-nav-logo.svg';
import CloseIcon from '../../../assets/images/mobile-nav-close-icon.svg';
import GlobeIcon from '../../../assets/images/mobile-nav-globe-icon.svg';

// interface
interface IProps {
  open: boolean;
  data_menu_1: any;
  data_menu_1_url: any;
  data_menu_2: any;
  data_menu_2_url: any;
  data_menu_3: any;
  data_menu_3_url: any;
  data_menu_4: any;
  data_menu_4_url: any;
  lang: string;
  setLanguage: (lang: string) => void;
  openMobileNav: (status: boolean) => void;
}

const MobileNavigation = (props: IProps): ReactElement => {
  // variables
  const {
    open,
    data_menu_1,
    data_menu_1_url,
    data_menu_2,
    data_menu_2_url,
    data_menu_3,
    data_menu_3_url,
    data_menu_4,
    data_menu_4_url,
    lang,
    setLanguage,
    openMobileNav,
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  // use states
  const [openNav, setOpenNav] = React.useState(false);

  // use effects
  React.useEffect(() => {
    document.addEventListener('mousedown', handleContainerClick, false);
    return () => {
      document.removeEventListener('mousedown', handleContainerClick, false);
    };
  });

  React.useEffect(() => {
    setOpenNav(open);
  }, [open]);

  // custom functions
  const handleContainerClick = (event: any) => {
    if (ref !== undefined) {
      if (ref.current !== null) {
        if (ref.current.contains(event.target)) {
          return;
        }
        openMobileNav(false);
      }
    }
  };

  const handleLanguage = (lang: string) => {
    setLanguage(lang);
  };

  const handleOpenMobileNav = () => {
    openMobileNav(false);
  };

  const getElementY = (query: any) => {
    return (
      window.pageYOffset +
      document.querySelector(query).getBoundingClientRect().top
    );
  };

  const doScrolling = (element: any, duration: number) => {
    const nav = document.getElementById('navigation');
    let navHeight = 0;
    if (nav) {
      navHeight = nav.clientHeight;
    }
    let startingY = window.pageYOffset;
    let elementY = getElementY(element);
    // If element is close to page's bottom then window will scroll only to some position above the element.
    let targetY =
      document.body.scrollHeight - elementY < window.innerHeight
        ? document.body.scrollHeight - window.innerHeight
        : elementY;
    let diff = targetY - startingY - navHeight;
    // Easing function: easeInOutCubic
    // From: https://gist.github.com/gre/1650294
    let easing = (t: any) => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };
    let start = 0;

    if (!diff) return;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;

      // Elapsed miliseconds since start of scrolling.
      let time = timestamp - start + navHeight;
      // Get percent of completion in range [0, 1].
      let percent = Math.min(time / duration, 1);
      // Apply the easing.
      // It can cause bad-looking slow frames in browser performance tool, so be careful.
      percent = easing(percent);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  };

  const handleReturnHome = (
    el: any,
    element: any,
    duration: number,
    delay: number
  ) => {
    el.persist();
    openMobileNav(false);
    setTimeout(() => {
      doScrolling(element, duration);
      const elems = document.querySelectorAll('.menus-li');
      [].forEach.call(elems, (el: any) => {
        el.classList.remove('active');
      });
      el.target.classList.add('active');
      if (element === '#header') {
        const elems = document.querySelectorAll('.menus-li');
        [].forEach.call(elems, (el: any) => {
          el.classList.remove('active');
        });
      }
    }, delay);
  };

  return (
    <div
      id="mobile-navigation"
      className={classNames(openNav === true ? 'open-it' : '')}
      ref={ref}
    >
      <div className="mobile-nav-header">
        <div>
          <img
            onClick={el => handleReturnHome(el, '#header', 600, 300)}
            src={MobileLogo}
            alt="mobile-nav-logo"
          />
        </div>
        <div className="close-icon-con">
          <img onClick={handleOpenMobileNav} src={CloseIcon} alt="close-icon" />
        </div>
      </div>
      <div className="mobile-nav-menus">
        <div
          onClick={el => handleReturnHome(el, data_menu_1_url, 600, 300)}
          className="menus-li"
        >
          {data_menu_1}
        </div>
        <div
          onClick={el => handleReturnHome(el, data_menu_2_url, 600, 300)}
          className="menus-li"
        >
          {data_menu_2}
        </div>
        <div
          onClick={el => handleReturnHome(el, data_menu_3_url, 600, 300)}
          className="menus-li"
        >
          {data_menu_3}
        </div>
        <div
          onClick={el => handleReturnHome(el, data_menu_4_url, 600, 300)}
          className="menus-li"
        >
          {data_menu_4}
        </div>
      </div>
      <div className="mobile-nav-translation">
        <div>
          <img src={GlobeIcon} alt="globe-icon" />{' '}
          <div className="separator"></div>
          <div className="lang">
            {lang === 'en-US' ? (
              <div onClick={() => handleLanguage('ja')}>日本語</div>
            ) : (
              <div onClick={() => handleLanguage('en-US')}>ENGLISH</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = ({language}: any) => ({
  lang: language.lang,
});

const actionsToProps = (dispatch: any) => ({
  setLanguage: (lang: string) => dispatch(SET_LANGUAGE(lang)),
});

export default connect(stateToProps, actionsToProps)(MobileNavigation);
