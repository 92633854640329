import React, {ReactElement} from 'react';
import ReactHTMLParser from 'react-html-parser';

// styles
import './style.scss';

// images
import HeaderLogo from '../../../assets/images/header-logo.svg';

// interface
interface IProps {
  data: any;
}

export default function Header(props: IProps): ReactElement {
  // variables
  const {data} = props;

  const handleFormatText = (text: string) => {
    let str = text.replace(/\r?\n|\r/g, '');
    if (str.indexOf('[highlight]')) {
      str = str.split('[highlight]').join('<span class="highlight">');
    }

    if (str.indexOf('[/highlight]')) {
      str = str.split('[/highlight]').join('</span>');
    }

    if (str.indexOf('[parenthesis]')) {
      str = str
        .split('[parenthesis]')
        .join('<span class="parenthesis">(</span>');
    }

    if (str.indexOf('[/parenthesis]')) {
      str = str
        .split('[/parenthesis]')
        .join('<span class="parenthesis">)</span>');
    }

    return ReactHTMLParser(str);
  };

  return (
    <div id="header">
      <img src={HeaderLogo} alt="header-logo" className="header-logo" />
      <h1>{handleFormatText(data)}</h1>
    </div>
  );
}
