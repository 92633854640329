import React, {ReactElement} from 'react';

// styles
import './style.scss';

// interface
interface IProps {
  data_title: any;
  data_track_record_1_title: any;
  data_track_record_1_superscript?: any;
  data_track_record_1_small_text?: any;
  data_track_record_1_sub_title?: any;
  data_track_record_1_description?: any;
  data_track_record_2_title: any;
  data_track_record_2_superscript?: any;
  data_track_record_2_small_text?: any;
  data_track_record_2_sub_title?: any;
  data_track_record_2_description?: any;
  data_track_record_3_title: any;
  data_track_record_3_superscript?: any;
  data_track_record_3_small_text?: any;
  data_track_record_3_sub_title?: any;
  data_track_record_3_description?: any;
}

export default function TrackRecords(props: IProps): ReactElement {
  // variables
  const {
    data_title,
    data_track_record_1_title,
    data_track_record_1_superscript,
    data_track_record_1_small_text,
    data_track_record_1_sub_title,
    data_track_record_1_description,
    data_track_record_2_title,
    data_track_record_2_superscript,
    data_track_record_2_small_text,
    data_track_record_2_sub_title,
    data_track_record_2_description,
    data_track_record_3_title,
    data_track_record_3_superscript,
    data_track_record_3_small_text,
    data_track_record_3_sub_title,
    data_track_record_3_description,
  } = props;

  return (
    <div id="track-records">
      <div className="container">
        <div className="header-text">
          <h1>{data_title}</h1>
        </div>
        <div className="records-text-block">
          <div>
            <h1>
              {data_track_record_1_title}
              {data_track_record_1_small_text !== '' ? (
                <span>{data_track_record_1_small_text}</span>
              ) : null}
              {data_track_record_1_superscript !== '' ? (
                <sup>{data_track_record_1_superscript}</sup>
              ) : null}
            </h1>
            <h3>{data_track_record_1_sub_title}</h3>
            <p>{data_track_record_1_description}</p>
          </div>
          <div>
            <h1>
              {data_track_record_2_title}
              {data_track_record_2_small_text !== '' ? (
                <span>{data_track_record_2_small_text}</span>
              ) : null}
              {data_track_record_2_superscript !== '' ? (
                <sup>{data_track_record_2_superscript}</sup>
              ) : null}
            </h1>
            <h3>{data_track_record_2_sub_title}</h3>
            <p>{data_track_record_2_description}</p>
          </div>
          <div>
            <h1>
              {data_track_record_3_title}
              {data_track_record_3_small_text !== '' ? (
                <span>{data_track_record_3_small_text}</span>
              ) : null}
              {data_track_record_3_superscript !== '' ? (
                <sup>{data_track_record_3_superscript}</sup>
              ) : null}
            </h1>
            <h3>{data_track_record_3_sub_title}</h3>
            <p>{data_track_record_3_description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
