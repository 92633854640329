import React, {ReactElement} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';

// actions
import {SET_LANGUAGE} from '../../actions/langugage';

// styles
import './style.scss';

// images
import Logo from '../../../assets/images/logo.svg';
import GlobeIcon from '../../../assets/images/globe-icon.svg';
import MobileMenuIcon from '../../../assets/images/mobile-menu-icon.svg';

// interface
interface IProps {
  data_menu_1: any;
  data_menu_1_url: any;
  data_menu_2: any;
  data_menu_2_url: any;
  data_menu_3: any;
  data_menu_3_url: any;
  data_menu_4: any;
  data_menu_4_url: any;
  lang: string;
  setLanguage: (lang: string) => void;
  openMobileNav: (status: boolean) => void;
}

const Navigation = (props: IProps): ReactElement => {
  // variables
  const {
    data_menu_1,
    data_menu_1_url,
    data_menu_2,
    data_menu_2_url,
    data_menu_3,
    data_menu_3_url,
    data_menu_4,
    data_menu_4_url,
    lang,
    setLanguage,
    openMobileNav,
  } = props;

  // use states
  const [scrolling, setScrolling] = React.useState(false);

  // use effects
  React.useEffect(() => {
    document.addEventListener('load', handleScrollEvent);
    document.addEventListener('scroll', handleScrollEvent);
    return () => {
      document.removeEventListener('load', handleScrollEvent);
      document.removeEventListener('scroll', handleScrollEvent);
    };
  });

  // custom functions
  const handleLanguage = (lang: string) => {
    setLanguage(lang);
  };

  const handleScrollEvent = () => {
    let scroll = window.scrollY;
    if (scroll >= 60) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const handleOpenMobileNav = () => {
    openMobileNav(true);
  };

  const getElementY = (query: any) => {
    return (
      window.pageYOffset +
      document.querySelector(query).getBoundingClientRect().top
    );
  };

  const doScrolling = (element: any, duration: number) => {
    const nav = document.getElementById('navigation');
    let navHeight = 0;
    if (nav) {
      navHeight = nav.clientHeight;
    }
    let startingY = window.pageYOffset;
    let elementY = getElementY(element);
    // If element is close to page's bottom then window will scroll only to some position above the element.
    let targetY =
      document.body.scrollHeight - elementY < window.innerHeight
        ? document.body.scrollHeight - window.innerHeight
        : elementY;
    let diff = targetY - startingY - navHeight;
    // Easing function: easeInOutCubic
    // From: https://gist.github.com/gre/1650294
    let easing = (t: any) => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };
    let start = 0;

    if (!diff) return;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;

      // Elapsed miliseconds since start of scrolling.
      let time = timestamp - start + navHeight;
      // Get percent of completion in range [0, 1].
      let percent = Math.min(time / duration, 1);
      // Apply the easing.
      // It can cause bad-looking slow frames in browser performance tool, so be careful.
      percent = easing(percent);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  };

  const handleReturnHome = (
    el: any,
    element: any,
    duration: number,
    delay: number
  ) => {
    el.persist();
    setTimeout(() => {
      doScrolling(element, duration);
      const elems = document.querySelectorAll('.menus-li');
      [].forEach.call(elems, (el: any) => {
        el.classList.remove('active');
      });
      el.target.classList.add('active');
      if (element === '#header') {
        const elems = document.querySelectorAll('.menus-li');
        [].forEach.call(elems, (el: any) => {
          el.classList.remove('active');
        });
      }
    }, delay);
  };

  return (
    <div
      id="navigation"
      className={classNames(scrolling === true ? 'scrolling' : '')}
    >
      <div className="container">
        <div className="logo">
          <img
            src={Logo}
            alt="fast-vb-logo"
            onClick={el => handleReturnHome(el, '#header', 600, 0)}
          />
        </div>
        <div className="menus">
          <ul>
            <li
              onClick={el => handleReturnHome(el, data_menu_1_url, 600, 0)}
              className="menus-li"
            >
              {data_menu_1}
            </li>
            <li
              onClick={el => handleReturnHome(el, data_menu_2_url, 600, 0)}
              className="menus-li"
            >
              {data_menu_2}
            </li>
            <li
              onClick={el => handleReturnHome(el, data_menu_3_url, 600, 0)}
              className="menus-li"
            >
              {data_menu_3}
            </li>
            <li
              onClick={el => handleReturnHome(el, data_menu_4_url, 600, 0)}
              className="menus-li"
            >
              {data_menu_4}
            </li>
          </ul>
        </div>
        <div className="translations">
          <img src={GlobeIcon} alt="globe-icon" />{' '}
          <div className="separator"></div>{' '}
          {lang === 'en-US' ? (
            <div onClick={() => handleLanguage('ja')}>日本語</div>
          ) : (
            <div onClick={() => handleLanguage('en-US')}>ENGLISH</div>
          )}
        </div>
        <div className="mobile-menu">
          <img
            onClick={handleOpenMobileNav}
            src={MobileMenuIcon}
            alt="mobile-menu"
          />
        </div>
      </div>
    </div>
  );
};

const stateToProps = ({language}: any) => ({
  lang: language.lang,
});

const actionsToProps = (dispatch: any) => ({
  setLanguage: (lang: string) => dispatch(SET_LANGUAGE(lang)),
});

export default connect(stateToProps, actionsToProps)(Navigation);
