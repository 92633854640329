import * as React from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {connect} from 'react-redux';

// actions
import {GET_HOMEPAGE_DATA} from './actions/homepage';
import {GET_NAVIGATION_MENUS} from './actions/navigation';

// styles
import '../stylesheets/index.scss';

// component
import Route from './settings/route';

// variables
const customHistory = createBrowserHistory();

const App = (props: any) => {
  // variables
  const {getHomepageData, getNavigationMenus} = props;

  // use Effects
  React.useEffect(() => {
    getHomepageData();
    getNavigationMenus();
    // eslint-disable-next-line
  }, []);

  return (
    <div id="FastVB">
      <Router history={customHistory}>
        <Route />
      </Router>
    </div>
  );
};

const stateToProps = () => ({});

const actionsToProps = (dispatch: any) => ({
  getHomepageData: () => dispatch(GET_HOMEPAGE_DATA()),
  getNavigationMenus: () => dispatch(GET_NAVIGATION_MENUS()),
});

export default connect(stateToProps, actionsToProps)(App);
