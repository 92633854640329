import React, {ReactElement} from 'react';
import {connect} from 'react-redux';

// styles
import './style.scss';

// components
import MobileNavigation from '../../components/mobile-navigation';
import Navigation from '../../components/navigation';
import Header from '../../components/header';
import QuestionBlock from '../../components/qestion-block';
import WhatIsSection from '../../components/what-is-section';
import AboutUs from '../../components/about-us';
import TrackRecords from '../../components/track-records';
import Technologies from '../../components/technologies';
import Contact from '../../components/contact';
import Footer from '../../components/footer';

const Home = (props: any): ReactElement => {
  // variables
  const {lang, homepageData, navigationMenus} = props;

  // use states
  const [openMobileNav, setOpenMobileNav] = React.useState(false);

  // use effects
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="home" className={lang}>
      {Object.keys(homepageData).length > 0 &&
      Object.keys(navigationMenus).length > 0 ? (
        <div className="wrapper">
          <MobileNavigation
            openMobileNav={(status: boolean) => setOpenMobileNav(status)}
            open={openMobileNav}
            data_menu_1={navigationMenus.menu_1[lang]}
            data_menu_1_url={navigationMenus.menu_1_url['en-US']}
            data_menu_2={navigationMenus.menu_2[lang]}
            data_menu_2_url={navigationMenus.menu_2_url['en-US']}
            data_menu_3={navigationMenus.menu_3[lang]}
            data_menu_3_url={navigationMenus.menu_3_url['en-US']}
            data_menu_4={navigationMenus.menu_4[lang]}
            data_menu_4_url={navigationMenus.menu_4_url['en-US']}
          />
          <Navigation
            openMobileNav={(status: boolean) => setOpenMobileNav(status)}
            data_menu_1={navigationMenus.menu_1[lang]}
            data_menu_1_url={navigationMenus.menu_1_url['en-US']}
            data_menu_2={navigationMenus.menu_2[lang]}
            data_menu_2_url={navigationMenus.menu_2_url['en-US']}
            data_menu_3={navigationMenus.menu_3[lang]}
            data_menu_3_url={navigationMenus.menu_3_url['en-US']}
            data_menu_4={navigationMenus.menu_4[lang]}
            data_menu_4_url={navigationMenus.menu_4_url['en-US']}
          />
          <Header
            data={homepageData.header_text[lang].content[0].content[0].value}
          />
          <QuestionBlock
            data_question={homepageData.question[lang]}
            data_answer={homepageData.answer[lang].content[0].content[0].value}
          />
          <WhatIsSection
            data={homepageData.venture_builder_title[lang]}
            data_description={
              homepageData.venture_builder_description[lang].content[0]
                .content[0].value
            }
            data_graph_title_first={homepageData.graph_title_first[lang]}
            data_graph_title_second={homepageData.graph_title_second[lang]}
            data_graph_title_third={homepageData.graph_title_third[lang]}
            data_graph_box_title_first={
              homepageData.graph_box_title_first[lang]
            }
            data_graph_box_description_first={
              homepageData.graph_box_description_first[lang].content[0]
                .content[0].value
            }
            data_graph_box_title_second={
              homepageData.graph_box_title_second[lang]
            }
            data_graph_box_description_second={
              homepageData.graph_box_description_second[lang].content[0]
                .content[0].value
            }
            data_graph_box_title_third={
              homepageData.graph_box_title_third[lang]
            }
            data_graph_box_description_third={
              homepageData.graph_box_description_third[lang].content[0]
                .content[0].value
            }
            data_graph_box_title_fourth={
              homepageData.graph_box_title_fourth[lang]
            }
            data_graph_box_description_fourth={
              homepageData.graph_box_description_fourth[lang].content[0]
                .content[0].value
            }
          />
          <AboutUs
            data_title={homepageData.about_us_title[lang]}
            about_us_image_header_text_title={
              homepageData.about_us_image_header_text_title[lang]
            }
            about_us_image_header_description={
              homepageData.about_us_image_header_description[lang].content[0]
                .content[0].value
            }
            about_us_text_block_1_title={
              homepageData.about_us_text_block_1_title[lang].content[0]
                .content[0].value
            }
            about_us_text_block_1_description={
              homepageData.about_us_text_block_1_description[lang].content[0]
                .content[0].value
            }
            about_us_text_block_2_title={
              homepageData.about_us_text_block_2_title[lang].content[0]
                .content[0].value
            }
            about_us_text_block_2_description={
              homepageData.about_us_text_block_2_description[lang].content[0]
                .content[0].value
            }
            about_us_text_block_3_title={
              homepageData.about_us_text_block_3_title[lang].content[0]
                .content[0].value
            }
            about_us_text_block_3_description={
              homepageData.about_us_text_block_3_description[lang].content[0]
                .content[0].value
            }
          />
          <TrackRecords
            data_title={homepageData.track_records_title[lang]}
            data_track_record_1_title={homepageData.track_record_1_title[lang]}
            data_track_record_1_superscript={
              homepageData.track_record_1_superscript
                ? homepageData.track_record_1_superscript[lang]
                : ''
            }
            data_track_record_1_small_text={
              homepageData.track_record_1_small_text
                ? homepageData.track_record_1_small_text[lang]
                : ''
            }
            data_track_record_1_sub_title={
              homepageData.track_record_1_sub_title
                ? homepageData.track_record_1_sub_title[lang]
                : ''
            }
            data_track_record_1_description={
              homepageData.track_record_1_description
                ? homepageData.track_record_1_description[lang]
                : ''
            }
            data_track_record_2_title={homepageData.track_record_2_title[lang]}
            data_track_record_2_superscript={
              homepageData.track_record_2_superscript
                ? homepageData.track_record_2_superscript[lang]
                : ''
            }
            data_track_record_2_small_text={
              homepageData.track_record_2_small_text
                ? homepageData.track_record_2_small_text[lang]
                : ''
            }
            data_track_record_2_sub_title={
              homepageData.track_record_2_sub_title
                ? homepageData.track_record_2_sub_title[lang]
                : ''
            }
            data_track_record_2_description={
              homepageData.track_record_2_description
                ? homepageData.track_record_2_description[lang]
                : ''
            }
            data_track_record_3_title={homepageData.track_record_3_title[lang]}
            data_track_record_3_superscript={
              homepageData.track_record_3_superscript
                ? homepageData.track_record_3_superscript[lang]
                : ''
            }
            data_track_record_3_small_text={
              homepageData.track_record_3_small_text
                ? homepageData.track_record_3_small_text[lang]
                : ''
            }
            data_track_record_3_sub_title={
              homepageData.track_record_3_sub_title
                ? homepageData.track_record_3_sub_title[lang]
                : ''
            }
            data_track_record_3_description={
              homepageData.track_record_3_description
                ? homepageData.track_record_3_description[lang]
                : ''
            }
          />
          <Technologies
            data_title={homepageData.technologies_title[lang]}
            data_list={
              homepageData.technologies_list[lang].content[0].content[0].value
            }
          />
          <Contact
            data_title={homepageData.contact_title[lang]}
            data_button_text={homepageData.contact_button_text[lang]}
          />
          <Footer
            data_address_1_country={homepageData.address_1_country[lang]}
            data_address_1_ca={
              homepageData.address_1_complete_address[lang].content[0]
                .content[0].value
            }
            data_address_2_country={homepageData.address_2_country[lang]}
            data_address_2_ca={
              homepageData.address_2_complete_address[lang].content[0]
                .content[0].value
            }
          />
        </div>
      ) : null}
    </div>
  );
};

const stateToProps = ({language, homepage, navigation}: any) => ({
  lang: language.lang,
  homepageData: homepage.data,
  navigationMenus: navigation.data,
});

const actionsToProps = () => ({});

export default connect(stateToProps, actionsToProps)(Home);
