import {SET_LANGUAGE} from '../actions/langugage';

// variables
const initialState = {
  lang: 'en-US',
};

export default function(state: object = initialState, action: any = null) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.lang,
      };
    default:
      return state;
  }
}
