import React, {ReactElement} from 'react';

// styles
import './style.scss';

// images
import Map from '../../../assets/images/map.png';
import MapMobile from '../../../assets/images/map-mobile.png';

// interface
interface IProps {
  data_title: any;
  data_button_text: any;
}

export default function Contact(props: IProps): ReactElement {
  // variables
  const {data_title, data_button_text} = props;

  return (
    <div id="contact">
      <div className="container">
        <h1>{data_title}</h1>
        <a href="mailto:info@fastvb.co" className="button">
          {data_button_text}
        </a>
        <img className="map" src={Map} alt="map" />
        <img className="map-mobile" src={MapMobile} alt="map" />
      </div>
    </div>
  );
}
