import React, {ReactElement} from 'react';
import ReactHTMLParser from 'react-html-parser';

// styles
import './style.scss';

// components
import VentureGraphBlock from '../venture-graph-block';

// interface
interface IProps {
  data: any;
  data_description: any;
  data_graph_title_first: any;
  data_graph_title_second: any;
  data_graph_title_third: any;
  data_graph_box_title_first: any;
  data_graph_box_description_first: any;
  data_graph_box_title_second: any;
  data_graph_box_description_second: any;
  data_graph_box_title_third: any;
  data_graph_box_description_third: any;
  data_graph_box_title_fourth: any;
  data_graph_box_description_fourth: any;
}

export default function WhatIsSection(props: IProps): ReactElement {
  // variables
  const {
    data,
    data_description,
    data_graph_title_first,
    data_graph_title_second,
    data_graph_title_third,
    data_graph_box_title_first,
    data_graph_box_description_first,
    data_graph_box_title_second,
    data_graph_box_description_second,
    data_graph_box_title_third,
    data_graph_box_description_third,
    data_graph_box_title_fourth,
    data_graph_box_description_fourth,
  } = props;

  // custom functions
  const handleFormatText = (text: string) => {
    let str = text;
    if (str.indexOf('[highlight]')) {
      str = str.split('[highlight]').join('<span class="highlight">');
    }

    if (str.indexOf('[/highlight]')) {
      str = str.split('[/highlight]').join('</span>');
    }
    return ReactHTMLParser(str);
  };

  return (
    <div id="what-is-section">
      <div className="container">
        <div className="section-header-text">
          <h1>{handleFormatText(data)}</h1>
          <p>{ReactHTMLParser(data_description)}</p>
        </div>
        <VentureGraphBlock
          data_graph_title_first={data_graph_title_first}
          data_graph_title_second={data_graph_title_second}
          data_graph_title_third={data_graph_title_third}
          data_graph_box_title_first={data_graph_box_title_first}
          data_graph_box_description_first={data_graph_box_description_first}
          data_graph_box_title_second={data_graph_box_title_second}
          data_graph_box_description_second={data_graph_box_description_second}
          data_graph_box_title_third={data_graph_box_title_third}
          data_graph_box_description_third={data_graph_box_description_third}
          data_graph_box_title_fourth={data_graph_box_title_fourth}
          data_graph_box_description_fourth={data_graph_box_description_fourth}
        />
      </div>
    </div>
  );
}
