import axios from 'axios';
import {API, NAVIGATION_MENUS_URL, ACCESS_TOKEN} from '../constant/api';

export const GET_NAVIGATION_MENUS = () => (dispatch: any) => {
  axios({
    method: 'get',
    url: NAVIGATION_MENUS_URL,
    baseURL: API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + ACCESS_TOKEN,
    },
  })
    .then(response => {
      dispatch({
        type: GET_NAVIGATION_MENUS,
        data: response.data.fields,
      });
    })
    .catch(error => {
      console.log(error.response);
    });
};
