import React, {ReactElement} from 'react';
import ReactHTMLParser from 'react-html-parser';

// styles
import './style.scss';

// interface
interface IProps {
  data_question: any;
  data_answer: any;
}

export default function QuestionBlock(props: IProps): ReactElement {
  // variables
  const {data_question, data_answer} = props;

  // custom functions
  const handleFormatText = (text: string) => {
    let str = text;
    if (str.indexOf('[underlined]')) {
      str = str.split('[underlined]').join('<span class="underlined">');
    }

    if (str.indexOf('[/underlined]')) {
      str = str.split('[/underlined]').join('</span>');
    }
    return ReactHTMLParser(str);
  };

  return (
    <div id="question-block">
      <div className="container">
        <div className="question-block-text">
          <h1>{handleFormatText(data_question)}</h1>
          <p>{ReactHTMLParser(data_answer)}</p>
        </div>
      </div>
    </div>
  );
}
